import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Sticky from 'react-stickynode';
import { Row, Col, Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InquiryFormComponent from './InquiryFormComponent';

const StyledDivWrapper = styled.div`
  .featured-header {
    background-color: ${props => props.theme.primeBluePalette.original};
    padding: 24px 0px;

    h2 {
      color: ${props => props.theme.whitePalette.original};
      margin-bottom: 4px;
    }

    p {
      color: ${props => props.theme.primeBluePalette.dark};
    }

    .specs {
      display: flex;

      .details {
        display: flex;
        align-items: center;

        .styled-icon {
          margin-right: 8px;
          font-size: 20px;
          color: ${props => props.theme.primeYellowPalette.original};
        }

        h5 {
          color: ${props => props.theme.whitePalette.original};
          margin: 0;
        }
      }

      .details:not(:first-child) {
        margin-left: 24px;
      }
    }

    .styled-divider {
      border-color: ${props => props.theme.primeBluePalette.dark};
      margin-bottom: 16px;
    }
  }

  .sticky-outer-wrapper.released > .sticky-inner-wrapper {
    z-index: 0 !important;
  }

  .sticky-outer-wrapper.active {
    .featured-header {
      padding: 12px 0px;
    }
    .header-divider,
    .specs {
      display: none;
    }
    .location {
      margin-bottom: 0px;
    }
  }
`;

function FeaturedHeaderComponent({
  projectListingDetails,
  handlers,
  formData,
  errorFields,
  loadingInquiry,
  loadingConsult,
  recaptchaRef,
  onSubmit,
}) {
  return (
    <StyledDivWrapper>
      <Sticky top={63} bottomBoundary={1350} innerZ={20}>
        <Row type="flex" justify="center" className="featured-header">
          <Col xxl={18} lg={20} md={22} sm={23} xs={23}>
            <Row type="flex" justify="space-between">
              <Col lg={14} sm={24} xs={24}>
                <h2>{projectListingDetails.propertyname}</h2>
                <p className="location">{projectListingDetails.location}</p>
                <Divider className="styled-divider header-divider" />
                <div className="specs">
                  <div className="details">
                    <FontAwesomeIcon icon="building" className="styled-icon" />
                    <h5>{projectListingDetails.classification}</h5>
                  </div>
                  <div className="details">
                    <FontAwesomeIcon icon="file-alt" className="styled-icon" />
                    <h5>{projectListingDetails.lease_sale}</h5>
                  </div>
                  <div className="details">
                    <FontAwesomeIcon
                      icon="expand-arrows-alt"
                      className="styled-icon"
                    />
                    <h5>{projectListingDetails.gfa} sqm</h5>
                  </div>
                </div>
              </Col>
              <Col lg={7} sm={0} xs={0}>
                <InquiryFormComponent
                  handlers={handlers}
                  formData={formData}
                  errorFields={errorFields}
                  loadingInquiry={loadingInquiry}
                  loadingConsult={loadingConsult}
                  recaptchaRef={recaptchaRef}
                  onSubmit={onSubmit}
                  propertyName={projectListingDetails.propertyname}
                  address={projectListingDetails.location}
                  propertyType={projectListingDetails.property_ype}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Sticky>
    </StyledDivWrapper>
  );
}

FeaturedHeaderComponent.propTypes = {
  projectListingDetails: PropTypes.object,
  handlers: PropTypes.object,
  formData: PropTypes.object,
  errorFields: PropTypes.object,
  loadingInquiry: PropTypes.bool,
  loadingConsult: PropTypes.bool,
  recaptchaRef: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default FeaturedHeaderComponent;
