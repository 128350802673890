import React, { useState, useEffect } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import styled from 'styled-components';

const StyledRadius = styled.div`
  height: ${props => `${props.size}px`};
  width: ${props => `${props.size}px`};
  border-radius: ${props => `${props.size}px`};
  background-color: ${props => `${props.theme.primeBluePalette.light}80`};
  border: ${props => `1px solid ${props.theme.primeBluePalette.dark}`};
`;

function ListingViewPageMap({ lng, lat }) {
  const tempViewport = {
    width: '100%',
    height: 400,
    latitude: lat,
    longitude: lng,
    zoom: 14,
    maxZoom: 16,
  };
  const [viewport, setViewport] = useState({ ...tempViewport });
  const [size, setSize] = useState(tempViewport.zoom * 6);

  useEffect(() => {
    const newSize = viewport.zoom < 15 ? viewport.zoom * 4 : viewport.zoom * 8;
    setSize(newSize);
  }, [viewport.zoom]);

  return (
    <ReactMapGL
      {...viewport}
      mapboxApiAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
      onViewportChange={newViewport => setViewport({ ...newViewport })}
    >
      <Marker
        latitude={lat}
        longitude={lng}
        offsetLeft={-Math.abs(size / 2)}
        offsetTop={-Math.abs(size / 2)}
      >
        <StyledRadius zoom={viewport.zoom} size={size} />
      </Marker>
    </ReactMapGL>
  );
}

export default ListingViewPageMap;
