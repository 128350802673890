/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import FeaturedHeaderComponent from './FeaturedHeaderComponent';
import ListingContentComponent from './ListingContentComponent';

const StyledListingViewPageContent = styled.div`
  max-width: 1440px;
  margin: auto;
  .featured-header {
    background-color: ${props => props.theme.primeBluePalette.original};
    padding: 24px 0px;

    h2 {
      color: ${props => props.theme.whitePalette.original};
      margin-bottom: 4px;
    }

    p {
      color: ${props => props.theme.primeBluePalette.dark};
    }

    .specs {
      display: flex;

      .details {
        display: flex;
        align-items: center;

        .styled-icon {
          margin-right: 8px;
          font-size: 20px;
          color: ${props => props.theme.primeYellowPalette.original};
        }

        h5 {
          color: ${props => props.theme.whitePalette.original};
          margin: 0;
        }
      }

      .details:not(:first-child) {
        margin-left: 24px;
      }
    }

    .styled-divider {
      border-color: ${props => props.theme.primeBluePalette.dark};
      margin-bottom: 16px;
    }
  }

  .contact-form {
    position: absolute;
    z-index: 2;
    padding: 14px;
    background-color: ${props => props.theme.whitePalette.original};
    width: 100%;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    height: 511px;
    max-height: 78vh;
    overflow-y: auto;
    overflow-x: hidden;

    h4 {
      border-left: ${props =>
        `4px solid ${props.theme.primeBluePalette.original}`};
      padding-left: 8px;
    }

    .input-label {
      font-size: 12px;
      margin-bottom: 2px;
    }

    .ant-input {
      background-color: ${props => props.theme.primeBluePalette.light};
      border: none;
      border-radius: 2px;
      margin-bottom: 8px;
    }

    .styled-divider {
      font-size: 14px;
      margin: 8px 0px;
    }

    .ant-btn {
      width: 100%;
    }

    .inquire-button {
      background-color: ${props => props.theme.primeYellowPalette.original};
      color: ${props => props.theme.blackPalette.original};
      border: none;
    }

    .google-recaptcha {
      margin-bottom: 8px;
    }

    @media only screen and (max-width: 991px) {
      position: relative;
      margin-top: 64px;
    }
  }

  .featured-content {
    padding: 24px 0px;

    .styled-feature {
      padding: 8px 14px;
      margin-bottom: 16px;

      h6 {
        margin-bottom: 4px;
        color: ${props => props.theme.primeBluePalette.dark};
      }
    }

    .primary {
      border-radius: 4px;
      border: ${props => `2px solid ${props.theme.primeBluePalette.original}`};

      h6 {
        color: ${props => props.theme.primeBluePalette.original};
      }
    }

    .styled-divider {
      border-color: ${props => props.theme.primeBluePalette.dark};
      margin-bottom: 16px;
    }
  }

  .slick-slide {
    background-color: #003366;

    img {
      margin-bottom: 0px;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 300px;
    }
  }

  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;
      align-items: center;
      justify-content: center;
    }
  }

  .slick-dots {
    position: absolute;
    text-align: center;
    bottom: 15px;
  }

  .slick-dots li button:before {
    font-size: 12px;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li.slick-active button:before {
    color: ${props => props.theme.whitePalette.original};
  }

  ::after {
    height: 100%;
    width: 100%;
    background-image: ${props => `url('${props.imgSrc}')`};
    background-size: cover;
    background-position: center;
    /* filter: blur(3px); */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    filter: blur(2px);
  }

  .slick-prev:before,
  .slick-next:before {
    color: ${props => props.theme.whitePalette.original};
  }
  .slick-prev {
    z-index: 1;
    left: 15px;
  }

  .slick-next {
    right: 15px;
  }

  .schedule-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    > div:first-child {
      padding: 0px 5px;
    }
    > div:last-child {
      font-weight: bold;
      padding: 6px 12px;
      color: ${props => props.theme.bluePalette.original};
      border: 1px solid ${props => props.theme.bluePalette.original};
      border-radius: 20px;
      cursor: pointer;
      :hover {
        color: ${props => props.theme.primeYellowPalette.original};
        background-color: ${props => props.theme.primeBluePalette.original};
      }
    }
  }
`;

const StyledHeroImage = styled.div`
  background-image: ${props =>
    `url('${props.coverImage}'), url('https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 360px;
`;

function ListingViewPageContent({
  projectListingDetails,
  handlers,
  formData,
  errorFields,
  loadingInquiry,
  loadingConsult,
  recaptchaRef,
  onSubmit,
}) {
  return (
    <StyledListingViewPageContent>
      <StyledHeroImage coverImage={projectListingDetails.banner} />
      <FeaturedHeaderComponent
        projectListingDetails={projectListingDetails}
        handlers={handlers}
        formData={formData}
        errorFields={errorFields}
        loadingInquiry={loadingInquiry}
        loadingConsult={loadingConsult}
        onSubmit={onSubmit}
      />
      <ListingContentComponent
        projectListingDetails={projectListingDetails}
        handlers={handlers}
        formData={formData}
        errorFields={errorFields}
        loadingInquiry={loadingInquiry}
        loadingConsult={loadingConsult}
        recaptchaRef={recaptchaRef}
        onSubmit={onSubmit}
        features={[
          {
            label: projectListingDetails.array_type
              ? 'Floor Area'
              : 'Gross Floor Area',
            value:
              projectListingDetails.gfa !== null &&
              `${projectListingDetails.gfa}`.toLowerCase() !== 'n/a' &&
              `${projectListingDetails.gfa}`.toLowerCase() !== 'not available'
                ? `${projectListingDetails.gfa} sqm`
                : 'Not Available',
            primary: true,
          },
          {
            label: projectListingDetails.array_type
              ? 'Lot Area'
              : 'Net Leasable Area',
            value:
              projectListingDetails.nla !== null &&
              `${projectListingDetails.nla}`.toLowerCase() !== 'n/a' &&
              `${projectListingDetails.nla}`.toLowerCase() !== 'not available'
                ? `${projectListingDetails.nla} sqm`
                : 'Not Available',
            primary: true,
          },
          {
            label: 'Frontage',
            value: projectListingDetails.frontage
              ? projectListingDetails.frontage
              : 'Not available',
            primary: true,
          },
          {
            label: 'Handover Condition',
            value: projectListingDetails.handover
              ? projectListingDetails.handover
              : 'Not available',
            primary: true,
          },
          {
            label: 'Operating Hours',
            value: projectListingDetails.operating_hours
              ? projectListingDetails.operating_hours
              : 'Not available',
            primary: false,
          },
          {
            label: 'Back-up Power',
            value: projectListingDetails.backup_power
              ? projectListingDetails.backup_power
              : 'Not available',
            primary: false,
          },
          {
            label: 'Number of Elevators',
            value: projectListingDetails.elevators
              ? projectListingDetails.elevators
              : 'Not available',
            primary: false,
          },
          {
            label: 'Building Level',
            value: projectListingDetails.level
              ? projectListingDetails.level
              : 'Not available',
            primary: false,
          },
          {
            label: 'Telco Providers',
            value: projectListingDetails.telco
              ? projectListingDetails.telco
              : 'Not available',
            primary: false,
          },
          {
            label: 'Proximities',
            value: projectListingDetails.proximities
              ? projectListingDetails.proximities
              : 'Not available',
            primary: false,
          },
        ]}
      />
    </StyledListingViewPageContent>
  );
}

ListingViewPageContent.propTypes = {
  projectListingDetails: PropTypes.object,
  handlers: PropTypes.object,
  formData: PropTypes.object,
  errorFields: PropTypes.object,
  loadingInquiry: PropTypes.bool,
  loadingConsult: PropTypes.bool,
  recaptchaRef: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default ListingViewPageContent;
