import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notification } from 'antd';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ListingViewPageContent from '../components/listing-view-page/ListingViewPageContent';
import ProjectPropertiesComponent from '../components/home-page/ProjectPropertiesComponent';

import { isNull } from '../utilities/formatChecker';
// import PlaceholderPageComponent from '../components/PlaceholderPageComponent';

const StyledDivWrapper = styled.div`
  padding-top: 89px;
`;

function ListingViewPage({ pageContext, location }) {
  const recaptchaRef = useRef(null);

  const [loadingInquiry, setLoadingInquiry] = useState(false);
  const [loadingConsult, setLoadingConsult] = useState(false);

  const [formData, setFormData] = useState({
    fullname: '',
    contactNumber: '',
    emailAddress: '',
    message: '',
    recaptcha: '',
  });

  const [errorFields, setErrorFields] = useState({
    fullname: '',
    contactNumber: '',
    emailAddress: '',
    message: '',
    recaptcha: '',
  });

  function fullNameOnChange(e) {
    setFormData({ ...formData, fullname: e.target.value });
  }

  function contactNoOnChange(e) {
    setFormData({ ...formData, contactNumber: e.target.value });
  }

  function emailAddressOnChange(e) {
    setFormData({ ...formData, emailAddress: e.target.value });
  }

  function messageOnChange(e) {
    setFormData({ ...formData, message: e.target.value });
  }

  function recaptchaOnChange(e) {
    setFormData({ ...formData, recaptcha: e });
  }

  function isFormDataComplete() {
    const formErrors = {
      fullname: isNull(formData.fullname.trim())
        ? 'Please provide your complete name'
        : '',
      contactNumber: isNull(formData.contactNumber.trim())
        ? 'Please provide your contact number'
        : '',
      emailAddress: isNull(formData.emailAddress.trim())
        ? 'Please provide your email address'
        : '',
      message: isNull(formData.message.trim())
        ? 'Please give us more information on your inquiry'
        : '',
      recaptcha: isNull(formData.recaptcha.trim())
        ? 'Please validate recaptcha to proceed'
        : '',
    };

    setErrorFields(formErrors);
    return Object.values(formErrors).filter(err => !isNull(err)).length === 0;
  }

  function onSubmit(type) {
    if (isFormDataComplete()) {
      if (type === 'Inquiry') setLoadingInquiry(true);
      if (type === 'Consult my property') setLoadingConsult(true);

      const dataToSubmit = {
        company_identifier: 'PRIMEPH',
        email_address: formData.emailAddress,
        data: [
          { key: 'Fullname', value: formData.fullname },
          { key: 'Email Address', value: formData.emailAddress },
          { key: 'Contact Number', value: formData.contactNumber },
          { key: 'Message', value: formData.message },
          {
            key: 'Type',
            value: type,
          },
          {
            key: 'Property Name',
            value: pageContext.projectListingDetails.propertyname,
          },
        ],
      };

      fetch(`${process.env.STRAPI_API}/email-requests`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSubmit),
      })
        .then(response => response.json())
        .then(() => {
          setFormData({
            fullname: '',
            contactNumber: '',
            emailAddress: '',
            message: '',
            recaptcha: '',
          });

          setErrorFields({
            fullname: '',
            contactNumber: '',
            emailAddress: '',
            message: '',
            recaptcha: '',
          });
          notification.success({
            message: 'Inquiry Sent',
            description:
              'Thank you for inquiring with us. Our team will get back to you as soon as we get it.',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Something Went Wrong!',
            description:
              'There was probleming sending your inquiry. Please try again later.',
          });
        })
        .finally(() => {
          setLoadingInquiry(false);
          setLoadingConsult(false);
        });
    } else
      notification.warning({
        message: 'Incomplete Fields',
        description: 'Please complete all required fields.',
      });
  }

  const relatedProperties = pageContext.relatedProperties
    .sort(() => Math.random() - 0.5)
    .slice(0, 7);

  return (
    <Layout>
      <SEO
        title={pageContext.projectListingDetails.propertyname}
        description={pageContext.projectListingDetails.meta_description}
        meta={[
          {
            property: 'og:image',
            content: pageContext.projectListingDetails.thumb,
          },
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content: pageContext.projectListingDetails.tags,
          },
        ]}
      />
      <StyledDivWrapper>
        <ListingViewPageContent
          projectListingDetails={pageContext.projectListingDetails}
          handlers={{
            fullNameOnChange,
            contactNoOnChange,
            emailAddressOnChange,
            messageOnChange,
            recaptchaOnChange,
          }}
          formData={formData}
          errorFields={errorFields}
          recaptchaRef={recaptchaRef}
          loadingInquiry={loadingInquiry}
          loadingConsult={loadingConsult}
          onSubmit={onSubmit}
        />

        <ProjectPropertiesComponent
          title={
            <h2>
              OTHER RELATED {pageContext.projectListingDetails.category}{' '}
              PROPERTIES
            </h2>
          }
          description={<p />}
          properties={relatedProperties}
          externalLink={{
            url:
              pageContext.projectListingDetails.category === 'OFFICE'
                ? 'https://offices.primephilippines.com/'
                : pageContext.projectListingDetails.category === 'RETAIL'
                ? 'https://commercialproperties.primephilippines.com/'
                : pageContext.projectListingDetails.category === 'INDUSTRIAL'
                ? 'https://commercialproperties.primephilippines.com/'
                : 'https://commercialproperties.primephilippines.com/',
            label: (
              <span>
                VIEW OTHER {pageContext.projectListingDetails.category}{' '}
                PROPERTIES
                <FontAwesomeIcon icon="angle-right" />
              </span>
            ),
          }}
          padding="50px 0px"
        />
        {/* <PlaceholderPageComponent /> */}
      </StyledDivWrapper>
    </Layout>
  );
}

ListingViewPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default ListingViewPage;
