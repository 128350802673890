/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Divider, Input, Button, Avatar } from 'antd';
import Recaptcha from 'react-google-recaptcha';
import AppointmentModalComponent from '../find-your-property/AppointmentModalComponent';

const { TextArea } = Input;

const StyledWrapper = styled.div`
  .contact-form {
    position: absolute;
    z-index: 2;
    padding: 14px;
    background-color: ${props => props.theme.whitePalette.original};
    width: 100%;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    height: 511px;
    max-height: 78vh;
    overflow-y: auto;
    overflow-x: hidden;

    h4 {
      border-left: ${props =>
        `4px solid ${props.theme.primeBluePalette.original}`};
      padding-left: 8px;
    }

    .input-label {
      font-size: 12px;
      margin-bottom: 2px;
    }

    .ant-input {
      background-color: ${props => props.theme.primeBluePalette.light};
      border: none;
      border-radius: 2px;
      margin-bottom: 8px;
    }

    .styled-divider {
      font-size: 14px;
      margin: 8px 0px;
    }

    .ant-btn {
      width: 100%;
    }

    .inquire-button {
      background-color: ${props => props.theme.primeYellowPalette.original};
      color: ${props => props.theme.blackPalette.original};
      border: none;
    }

    .google-recaptcha {
      margin-bottom: 8px;
    }

    .google-recaptcha {
      transform: scale(0.75);
      transform-origin: 0 0;
    }

    @media only screen and (max-width: 991px) {
      position: relative;
      margin-top: 64px;
    }
  }

  .schedule-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    > div:first-child {
      padding: 0px 5px;
    }
    > div:last-child {
      font-weight: bold;
      padding: 6px 11px;
      color: ${props => props.theme.bluePalette.original};
      border: 1px solid ${props => props.theme.bluePalette.original};
      border-radius: 20px;
      cursor: pointer;
      :hover {
        color: ${props => props.theme.primeYellowPalette.original};
        background-color: ${props => props.theme.primeBluePalette.original};
      }
    }
  }
`;

function InquiryFormComponent({
  handlers,
  formData,
  errorFields,
  loadingInquiry,
  // loadingConsult,
  recaptchaRef,
  onSubmit,
  propertyName,
  address,
  propertyType,
}) {
  const data = useStaticQuery(graphql`
    {
      allPropertiesAllUsers {
        nodes {
          id
          status
          profile_pic
          position
          name
          focus
          email
          department
          area
        }
      }
    }
  `);
  const [isImgErr, setIsImgErr] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  function getUsersAvatar(pType) {
    let focusType = 'Office';
    switch (pType) {
      case 'Office':
        focusType = 'Office';
        break;
      case 'Retail':
      case 'IW':
      case 'IL':
      case 'CL':
        focusType = 'Retail';
        break;

      default:
        focusType = 'Office';
        break;
    }

    return data.allPropertiesAllUsers.nodes
      .map(user => ({
        ...user,
        profile_pic:
          user.profile_pic !== 'uploads/prof/dummy_prof.png'
            ? `https://assets.primecore.dev/users/profile_images/${user.profile_pic}`
            : ``,
      }))
      .sort(() => Math.random() - 0.5)
      .filter(user => user.focus === focusType)
      .slice(0, 3);
  }
  return (
    // <Affix offsetTop={116}>
    <StyledWrapper>
      <div className="contact-form">
        <AppointmentModalComponent
          visibility={modalOpen}
          onVisibilityChanged={() => {
            setModalOpen(false);
          }}
          propertyName={propertyName}
          address={address}
          propertyType={propertyType}
        />
        <h4>Feel free to ask our team!</h4>
        <div
          className={`input-field-wrapper ${
            errorFields.fullname ? 'has-error' : ''
          }`}
        >
          <div className="input-label">Full Name</div>
          <Input
            onChange={e => handlers.fullNameOnChange(e)}
            value={formData.fullname}
            size="small"
          />
          {errorFields.fullname ? (
            <div className="error-explain">{errorFields.fullname}</div>
          ) : null}
        </div>
        <div
          className={`input-field-wrapper ${
            errorFields.contactNumber ? 'has-error' : ''
          }`}
        >
          <div className="input-label">Contact Number</div>
          <Input
            onChange={e => handlers.contactNoOnChange(e)}
            value={formData.contactNumber}
            size="small"
          />
          {errorFields.contactNumber ? (
            <div className="error-explain">{errorFields.contactNumber}</div>
          ) : null}
        </div>
        <div
          className={`input-field-wrapper ${
            errorFields.emailAddress ? 'has-error' : ''
          }`}
        >
          <div className="input-label">Email Address</div>
          <Input
            onChange={e => handlers.emailAddressOnChange(e)}
            value={formData.emailAddress}
            size="small"
          />
          {errorFields.emailAddress ? (
            <div className="error-explain">{errorFields.emailAddress}</div>
          ) : null}
        </div>
        <div
          className={`input-field-wrapper ${
            errorFields.message ? 'has-error' : ''
          }`}
        >
          <div className="input-label">Message</div>
          <TextArea
            onChange={e => handlers.messageOnChange(e)}
            value={formData.message}
            size="small"
          />
          {errorFields.message ? (
            <div className="error-explain">{errorFields.message}</div>
          ) : null}
        </div>
        <div
          className={`google-recaptcha input-field-wrapper ${
            errorFields.recaptcha ? 'has-error' : ''
          }`}
        >
          <Recaptcha
            ref={recaptchaRef}
            sitekey="6LdehiUTAAAAALj4VhxGS0EEMsC6aech1yN8UFfk"
            onChange={e => handlers.recaptchaOnChange(e)}
            // size="compact"
          />
          {errorFields.recaptcha ? (
            <div className="error-explain">{errorFields.recaptcha}</div>
          ) : null}
        </div>
        <Button
          type="primary"
          className="inquire-button"
          loading={loadingInquiry}
          onClick={() => onSubmit('Inquiry')}
        >
          Inquire now!
        </Button>
        <Divider className="styled-divider">or</Divider>
        {/* <Button
          type="primary"
          loading={loadingConsult}
          onClick={() => onSubmit('Consult my property')}
        >
          Lease your property with us
        </Button> */}
        <div>
          <p>Schedule an appointment with our sales team.</p>
          <div className="schedule-button">
            <Avatar.Group>
              {getUsersAvatar(propertyType).map((user, i) => (
                <Avatar
                  key={`${i}${user.name}`}
                  src={`${user.profile_pic}`}
                  alt={`${user.name}`}
                  onError={() => setIsImgErr(true)}
                  style={{
                    backgroundColor: '#003366',
                    color: '#fad127',
                  }}
                >
                  {isImgErr ? `${user.name}`.toUpperCase().charAt(0) : null}
                </Avatar>
              ))}
            </Avatar.Group>
            <div
              onClick={() => {
                setModalOpen(true);
              }}
              aria-label="Appointment Button"
              onKeyDown={null}
              role="button"
              tabIndex="0"
            >
              Book an appointment?
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
    // </Affix>
  );
}

InquiryFormComponent.propTypes = {
  handlers: PropTypes.object,
  formData: PropTypes.object,
  errorFields: PropTypes.object,
  loadingInquiry: PropTypes.bool,
  // loadingConsult: PropTypes.bool,
  recaptchaRef: PropTypes.object,
  onSubmit: PropTypes.func,
  propertyName: PropTypes.string,
  address: PropTypes.string,
  propertyType: PropTypes.string,
};
export default InquiryFormComponent;
