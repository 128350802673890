/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Row, Col, Divider } from 'antd';
import Slider from 'react-slick';
import LazyImage from 'react-lazy-progressive-image';

import InquiryFormComponent from './InquiryFormComponent';
import ListingViewPageMap from './ListingViewPageMap';

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledWrapper = styled.div`
  .featured-content {
    padding: 24px 0px;

    .styled-feature {
      padding: 8px 14px;
      margin-bottom: 16px;

      h6 {
        margin-bottom: 4px;
        color: ${props => props.theme.primeBluePalette.dark};
      }
    }

    .primary {
      border-radius: 4px;
      border: ${props => `2px solid ${props.theme.primeBluePalette.original}`};

      h6 {
        color: ${props => props.theme.primeBluePalette.original};
      }
    }

    .styled-divider {
      border-color: ${props => props.theme.primeBluePalette.dark};
      margin-bottom: 16px;
    }
  }

  .slick-slide {
    background-color: #003366;

    img {
      margin-bottom: 0px;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 300px;
    }
  }

  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;
      align-items: center;
      justify-content: center;
    }
  }

  .slick-dots {
    position: absolute;
    text-align: center;
    bottom: 15px;
  }

  .slick-dots li button:before {
    font-size: 12px;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li.slick-active button:before {
    color: ${props => props.theme.whitePalette.original};
  }

  ::after {
    height: 100%;
    width: 100%;
    background-image: ${props => `url('${props.imgSrc}')`};
    background-size: cover;
    background-position: center;
    /* filter: blur(3px); */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    filter: blur(2px);
  }

  .slick-prev:before,
  .slick-next:before {
    color: ${props => props.theme.whitePalette.original};
  }
  .slick-prev {
    z-index: 1;
    left: 15px;
  }

  .slick-next {
    right: 15px;
  }
`;

function ListingContentComponent({
  features,
  projectListingDetails,
  handlers,
  formData,
  errorFields,
  loadingInquiry,
  loadingConsult,
  recaptchaRef,
  onSubmit,
}) {
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <StyledWrapper>
      <Row
        type="flex"
        justify="center"
        align="bottom"
        className="featured-content"
      >
        <Col xxl={18} lg={20} md={22} sm={23} xs={23}>
          <Row type="flex" justify="space-between">
            <Col lg={12} sm={24} xs={24}>
              <h3>Property Features</h3>
              <Row type="flex" gutter={16}>
                {features.map(detail => (
                  <Col sm={12} xs={24} key={detail.label}>
                    <div
                      className={`styled-feature ${detail.primary &&
                        'primary'}`}
                    >
                      <h6 className="label">{detail.label}</h6>
                      <div>{detail.value}</div>
                    </div>
                  </Col>
                ))}
              </Row>
              <Divider className="styled-divider" />
              <h3>Description</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: projectListingDetails.content,
                }}
              />

              {projectListingDetails.images ? (
                projectListingDetails.images.data ? (
                  projectListingDetails.images.data.length > 0 ? (
                    <div>
                      <Divider className="styled-divider" />
                      <h3>Gallery</h3>
                      <Slider {...settings}>
                        {projectListingDetails.images.data.map(listing => (
                          <div className="slick-slide" key={listing.image_path}>
                            <LazyImage
                              placeholder="https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg"
                              src={`${process.env.IMAGES_URL_PATH}/${listing.image_path}`}
                            >
                              {src => (
                                <img
                                  src={src}
                                  alt={projectListingDetails.propertyname}
                                />
                              )}
                            </LazyImage>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  ) : null
                ) : null
              ) : null}

              {projectListingDetails.longitude &&
              projectListingDetails.latitude ? (
                <div>
                  <Divider className="styled-divider" />
                  <h3>Location</h3>
                  <ListingViewPageMap
                    lng={projectListingDetails.longitude}
                    lat={projectListingDetails.latitude}
                  />
                </div>
              ) : null}
            </Col>
            <Col lg={0} sm={24} xs={24}>
              <InquiryFormComponent
                handlers={handlers}
                formData={formData}
                errorFields={errorFields}
                loadingInquiry={loadingInquiry}
                loadingConsult={loadingConsult}
                recaptchaRef={recaptchaRef}
                onSubmit={onSubmit}
                propertyName={projectListingDetails.propertyname}
                address={projectListingDetails.address}
                propertyType={projectListingDetails.property_ype}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledWrapper>
  );
}

ListingContentComponent.propTypes = {
  features: PropTypes.array,
  projectListingDetails: PropTypes.object,
  handlers: PropTypes.object,
  formData: PropTypes.object,
  errorFields: PropTypes.object,
  loadingInquiry: PropTypes.bool,
  loadingConsult: PropTypes.bool,
  recaptchaRef: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default ListingContentComponent;
